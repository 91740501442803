<template>
  <div class="service-address">
    <div class="addressUl" v-if="addressList.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div
          class="addressLi"
          v-for="item in addressList"
          :key="item.id"
          @click.stop="selectAddress(item)"
        >
          <p>
            <span class="name-p"
              >{{ item.consigneeName }}
              {{ item.gender == 1 ? "先生" : "女士" }}
              {{
                item.consigneeMobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
              }}</span
            ><span class="address">{{ item.detailAddress }}</span>
          </p>
          <svg-icon
            icon-class="edit"
            class="editIcon"
            @click.stop="editAddress('1', item)"
          ></svg-icon>
        </div>
      </van-list>
    </div>
    <div class="nullDataDiv" v-else>
      <img src="../../assets/img/error.png" alt="" />
      <span class="text">暂无数据</span>
    </div>
    <div class="addBtn" @click="editAddress('2')">新增服务地址</div>
  </div>
</template>

<script>
import { pageServeAddress } from "@/api/my";
export default {
  data() {
    return {
      page: 1,
      loading: false,
      finished: false,
      total: 0,
      addressList: []
    };
  },
  created() {
    this.pageServeAddress();
  },
  methods: {
    // 点击选择地址
    selectAddress(item) {
      console.log("locationCode", localStorage.getItem("locationCode"));
      let locationCode = localStorage.getItem("locationCode") || "010";
      if (this.$route.query.from) {
        sessionStorage.setItem("orderSelectAddress", JSON.stringify(item));
        this.$router.go(-1);
        // if (locationCode !== item.cityCode) {
        //   this.$toast("当前地址不可用");
        // } else {
        //   sessionStorage.setItem("orderSelectAddress", JSON.stringify(item));
        //   this.$router.go(-1);
        // }
      }
    },
    // 编辑地址 1 修改 2新增
    editAddress(type, item) {
      let pushQuery;
      if (type == 1) {
        pushQuery = {
          id: item.id
        };
      }
      this.$router.push({
        path: "/addAddress",
        query: pushQuery
      });
    },
    // 获取我的服务地址
    pageServeAddress() {
      let data = {
        pageIndex: this.page,
        pageSize: 10
      };
      pageServeAddress(data).then(res => {
        this.total = res.data.total;
        if (res.data.records.length > 0) {
          this.addressList = this.addressList.concat(res.data.records);
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    // 分页
    onLoad() {
      if (this.addressList.length < this.total) {
        this.page++;
        this.pageServeAddress();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.service-address {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .addressUl {
    flex: 1;
    .addressLi {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #e3e3e3;
      padding: 0.4rem;
      p {
        display: flex;
        flex-direction: column;
        .name-p{
          font-size: .4rem;
        }
        .address {
          font-size: 0.28rem;
          margin-top: 0.2rem;
          color: grey;
        }
      }
      .editIcon {
        font-size: 0.5rem;
      }
    }
  }
  .addBtn {
    position: sticky;
    bottom: 0;
    font-size: 0.4rem;
    font-weight: bold;
    background: rgb(236, 25, 25);
    text-align: center;
    padding: 0.5rem;
    color: #fff;
  }
}
</style>
